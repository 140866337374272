import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import JfcContributors from "../../components/contributors/JfcContributors";

const TeamPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | JFC Contributors">
            <NavOne />
            <PageHeader title="JFC Contributors" />
            <JfcContributors />
            <Footer />
        </Layout>
    );
};

export default TeamPage;
